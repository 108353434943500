.mavy-tooltip {
    background-color: #D8B2FA; /* Customize background color */
    color: white; /* Customize text color */
    font-family: 'Roboto', sans-serif; /* Customize font family */
    font-size: 14px; /* Customize font size */
    padding: 2px; /* Customize padding */
    border-radius: 10px; /* Customize border radius */
  }
  
  /* Targeting the arrow's color based on placement */
  .mavy-tooltip[data-placement^='top'] .tippy-arrow::before {
    border-top-color: #D8B2FA; /* Change arrow color for top placement */
  }
  
  .mavy-tooltip[data-placement^='bottom'] .tippy-arrow::before {
    border-bottom-color: #D8B2FA; /* Change arrow color for bottom placement */
  }
  
  .mavy-tooltip[data-placement^='left'] .tippy-arrow::before {
    border-left-color: #D8B2FA; /* Change arrow color for left placement */
  }
  
  .mavy-tooltip[data-placement^='right'] .tippy-arrow::before {
    border-right-color: #D8B2FA; /* Change arrow color for right placement */
  }
  .mavy-tooltip[data-placement^='top-end'] .tippy-arrow::before {
    border-right-color: #D8B2FA; /* Change arrow color for right placement */
  }

  